import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, provideAppInitializer } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@pm/config';

import { JwtToken } from '../models';
import { PmAuthService } from '../services/pm-auth-service.service';

export const authSsoProvider = provideAppInitializer(() => {
  const initializerFn = ((
    auth: PmAuthService,
    http: HttpClient,
    appConfig: AppConfig,
  ) => {
    return async () => {
      if (!window) {
        return;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const sso = urlParams.get('sso');
      if (sso) {
        try {
          const tokens$ = http.get<JwtToken>(appConfig.api + '/jwt/token', {
            params: {
              _format: 'json',
            },
            headers: {
              Authorization: 'Bearer ' + sso,
            },
          });

          const tokens = await firstValueFrom(tokens$);
          await auth.setTokens(tokens);
        } catch (error) {
          await auth.setToken(sso);
        }

        const allOtherParams = new URLSearchParams(window.location.search);
        allOtherParams.delete('sso');
        history.replaceState(
          null,
          '',
          allOtherParams.toString()
            ? window.location.pathname + '?' + allOtherParams
            : window.location.pathname,
        );
      }
      return;
    };
  })(inject(PmAuthService), inject(HttpClient), inject<AppConfig>(APP_CONFIG));
  return initializerFn();
});
