import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { HttpCacheManager } from '@ngneat/cashew';
import { isPlatformBrowser } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { APP_TYPE, AppType } from '@pm/core/utils';

/**
 * INFO: Async methods to be compable with different storage providers
 */
@Injectable({
  providedIn: 'root',
})
export class PmAuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isBrowser = false;
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly _cacheManager: HttpCacheManager,
    @Inject(PLATFORM_ID) platformId: string,
    private readonly _transloco: TranslocoService,
    @Inject(APP_TYPE) private _appType: AppType,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this._init();
  }

  async setTokens(tokens: { token: string; refreshToken?: string }) {
    if (!this.isBrowser) {
      return;
    }
    localStorage.setItem('token', tokens.token);
    await this._setTranslation();
    if (tokens.refreshToken) {
      localStorage.setItem('refresh_token', tokens.refreshToken);
    }

    this.isLoggedIn$.next(true);
  }

  async setToken(token: string) {
    if (!this.isBrowser) {
      return;
    }
    localStorage.setItem('token', token);
    await this._setTranslation();
    if (token) {
      this.isLoggedIn$.next(true);
    }
  }

  async getToken() {
    if (!this.isBrowser) {
      return '';
    }
    const token = localStorage.getItem('token');
    return token ? token : '';
  }

  async setRefreshToken(refreshToken: string) {
    if (!this.isBrowser) {
      return;
    }
    localStorage.setItem('refresh_token', refreshToken);
  }

  async getRefreshToken() {
    if (!this.isBrowser) {
      return;
    }
    return localStorage.getItem('refresh_token');
  }

  async getDecodedToken(): Promise<{
    drupal: {
      uid: string;
      displayname?: string;
      email?: string;
      phone?: string;
      langcode?: string;
      push_enabled?: boolean;
    };
  }> {
    try {
      return jwt_decode(await this.getToken());
    } catch (Error) {
      return { drupal: { uid: '' } };
    }
  }

  async isLoggedIn() {
    const token = await this.getToken();
    return !!token;
  }

  async signOut() {
    this._cacheManager.clear();
    localStorage.removeItem('token');
    this.isLoggedIn$.next(false);
  }

  async openDashboard(pathname?: string) {
    if (this.appConfig.dashboard) {
      const url = new URL(this.appConfig.dashboard + (pathname ?? ''));
      url.searchParams.set('sso', await this.getToken());
      const windowProxy = window.open(url, '_blank');
      if (windowProxy) {
        windowProxy.focus();
      } else {
        window.location.href = url.toString();
      }
    }
  }

  private async _init() {
    const loggedIn = await this.isLoggedIn();
    this.isLoggedIn$.next(loggedIn);
  }

  private async _setTranslation() {
    if (this._appType === AppType.dashboard) {
      const { drupal } = await this.getDecodedToken();
      if (drupal.langcode) {
        this._transloco.setActiveLang(drupal.langcode);
        this._transloco.load(drupal.langcode);
      }
    }
  }
}
