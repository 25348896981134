import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RESPONSE } from '@pm/core/utils';
import { ButtonModule } from 'primeng/button';
import { Response } from 'express';

@Component({
    selector: 'pm-core-page-not-found',
    imports: [CommonModule, ButtonModule, RouterModule, TranslocoModule],
    templateUrl: './pm-core-page-not-found.component.html',
    styleUrls: ['./pm-core-page-not-found.component.scss']
})
export class PmCorePageNotFoundComponent implements OnInit {
  constructor(@Optional() @Inject(RESPONSE) private response: Response) {}

  ngOnInit() {
    if (this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = '404 - Page Not Found';
    }
  }
}
