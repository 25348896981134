import { firstValueFrom } from 'rxjs';

import { Injector, NgModule, inject, provideAppInitializer } from '@angular/core';
import {
  TranslocoModule,
  TranslocoService,
  provideTransloco,
} from '@ngneat/transloco';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';

import { TranslocoHttpLoader } from './transloco-loader';

export function setMainLanguage(
  injector: Injector,
  transloco: TranslocoService,
  auth: PmAuthService,
) {
  return async function () {
    const config = injector.get(APP_CONFIG) as AppConfig;

    let localeCode = config.localeCode ?? 'en';
    /** Disable ru for dashboard */
    if (localeCode === 'ru') {
      localeCode = 'uk';
    }

    const {
      drupal: { langcode },
    } = await auth.getDecodedToken();
    const lang = langcode ?? localeCode;
    transloco.setActiveLang(lang);
    return firstValueFrom(transloco.load(lang));
  };
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [
          'zh',
          'tr',
          'sl',
          'hu',
          'lt',
          'nl',
          'de',
          'pl',
          'uk',
          'en',
          'pt-pt',
          'it',
          'fr',
          'el',
          'ro',
          'hi',
          'cs',
          'da',
          'sv',
          'es',
          'ar',
        ],
        defaultLang: 'en',
        reRenderOnLangChange: true,
      },
      loader: TranslocoHttpLoader,
    }),
    provideAppInitializer(() => {
        const initializerFn = (setMainLanguage)(inject(Injector), inject(TranslocoService), inject(PmAuthService));
        return initializerFn();
      }),
  ],
})
export class TranslocoRootModule {}
