import { APP_CONFIG, AppConfig } from '@pm/config';
import { Injector, inject, provideAppInitializer } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { NotificationsFacade } from '../stores';
import { PmAuthService } from '@pm/auth/utils';
import { initializeApp } from 'firebase/app';

export const notificationsUpdatesProvider = provideAppInitializer(() => {
        const initializerFn = ((
    notification: NotificationsFacade,
    injector: Injector,
    auth: PmAuthService,
  ) => {
    return async () => {
      const config = injector.get(APP_CONFIG) as AppConfig;
      if (config.firebase && Capacitor.getPlatform() === 'web') {
        initializeApp(config.firebase);
      }
      await FirebaseMessaging.addListener('notificationReceived', (event) => {
        console.log('notificationReceived: ', { event });
        notification.init();
      });

      if (!(await auth.isLoggedIn())) {
        return auth.isLoggedIn$.subscribe(async (isLoggedIn) => {
          if (isLoggedIn) {
            notification.init();
          }
        });
      }

      notification.init();

      return;
    };
  })(inject(NotificationsFacade), inject(Injector), inject(PmAuthService));
        return initializerFn();
      });
