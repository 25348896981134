import { Component } from '@angular/core';

import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html',
    standalone: false
})
export class AppProfileSidebarComponent {

    constructor(public layoutService: LayoutService) { }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    onClose() {
        this.visible = false;
    }
}
