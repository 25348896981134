import '@angular/common/locales/global/ar';
import '@angular/common/locales/global/cs';
import '@angular/common/locales/global/da';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/el';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/et';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/hi';
import '@angular/common/locales/global/hu';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/lt';
import '@angular/common/locales/global/nl';
import '@angular/common/locales/global/pl';
import '@angular/common/locales/global/pt-PT';
import '@angular/common/locales/global/ro';
import '@angular/common/locales/global/sl';
import '@angular/common/locales/global/sv';
import '@angular/common/locales/global/tr';
import '@angular/common/locales/global/uk';
import '@angular/common/locales/global/zh';

import {
  getAllDataFromLocalForage,
  default as localForage,
} from 'ngrx-store-persist';
import { defineCustomElements } from 'stripe-pwa-elements/loader';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG } from '@pm/config';
import { APP_TYPE, AppType } from '@pm/core/utils';
import { APP_VERSION } from '@pm/core/version';
import * as Sentry from '@sentry/angular';

import packageJson from '../package.json';
import { AppModule } from './app/app.module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let process: any;
const env = process.env.NODE_ENV;

const configFile = env === 'development' ? 'config.dev.json' : 'config.json';

// eslint-disable-next-line @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

function bootstrap() {
  getAllDataFromLocalForage({
    driver: localForage.INDEXEDDB,
    keys: ['applicationForm', 'domainsForm'],
  }).then(() => {
    fetch(`./config/${configFile}`)
      .then((resp) => resp.json())
      .then((config) => {
        Sentry.init({
          release: [packageJson.name, packageJson.version].join('@'),
          dsn: config.dns,
          enabled: env === 'production',
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
            }),
          ],
          tracePropagationTargets: ['localhost', /^https:\/\/ideabox\.name/],
          // Performance Monitoring
          tracesSampleRate: 1.0,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        });
        platformBrowserDynamic([
          { provide: APP_CONFIG, useValue: config },
          { provide: APP_TYPE, useValue: AppType.dashboard },
          {
            provide: APP_VERSION,
            useValue: packageJson.version,
          },
        ])
          .bootstrapModule(AppModule)
          .then(() => defineCustomElements(window))
          .catch((err) => console.error(err));
      });
  });
}

bootstrap();
